import React from 'react'
import Layout from '../components/layout/Layout'
import { Section } from '../pages/index'
import { Button } from '../components/elements'
import SEO from "@layout/SEO"

const Error = props => {
    return (
      <>
        <SEO title="Whoops.." description="Could have sworn there was something here..." />

        <Layout>
            <Section className="justify-center flex-col text-center px-4">
                <i className="fa fa-spider text-6xl my-4 text-accent"></i>
                <h2>Oooo whooops</h2>
                <h5>This page doesn't exist...</h5>
                <Button linkTo="/" className="mx-auto block mt-8">Back to normality</Button>
            </Section>
        </Layout>
        </>
    )
}

Error.propTypes = {

}

export default Error
